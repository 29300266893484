<script>
import api from '@/command/api'
import TemplateHeaderTable from '../../../components/TemplateHeaderTable'
export default {
  name: 'rural',
  data() {
    return {
      ...api.command.getState()
    }
  },
  mounted() {
    api.command.getList.call(this, {
      url: '/market/farmCountryImpress/page',
      current: 1
    })
  },
  methods: {
    getHeader() {
      return [
        {
          name: '名称',
          key: 'name',
          type: 'input'
        },
        {
          name: '游玩地点',
          type: 'input',
          key: 'address'
        },
        {
          name: 'ID',
          type: 'input',
          key: 'id'
        }
      ]
    },
    getColumns() {
      return [
        {
          dataIndex: 'id',
          title: 'ID',
          align: 'left',
          sorter: (a, b) => a.id - b.id
        },
        {
          dataIndex: 'name',
          title: '名称',
          align: 'left',
          sorter: (a, b) => a.name.length - b.name.length,
          customRender: (text, records) => {
            return (
              <div class="product-order-item-copy">
                <img src={records.image2} />
                <div>{text}</div>
              </div>
            )
          }
          //   onExport: (text, records) => {
          //     return `${records.image2} / ${text}`
          //   }
        },
        {
          dataIndex: 'address',
          title: '游玩地点',
          align: 'left'
        },
        {
          dataIndex: 'farmLabelDataVOS',
          title: '标签',
          align: 'left',
          getData: records => records.farmLabelDataVOS.slice(0, 5).map(e => e.labelName),
          type: 'tagGroup',
          onExport: records => {
            let arr = records.map(e => {
              return e.labelName
            })
            return arr.toString()
          }
        },
        {
          dataIndex: 'upDown',
          title: '状态',
          type: 'badge',
          filters: [
            {
              text: '上架',
              value: '0'
            },
            {
              text: '下架',
              value: '1'
            }
          ],
          onExport: records => {
            return ['上架', '下架'][records]
          },
          filterMultiple: false,
          render(data) {
            return {
              showDot: true,
              name: data == 0 ? '上架' : '下架',
              color: data == 0 ? 'green' : 'red'
            }
          }
        },
        {
          dataIndex: 'a9',
          title: '操作',
          type: 'buttonGroup',
          typeData: ({ records }) => {
            return [
              {
                name: records.upDown == 0 ? '下架' : '上架',
                onClick: () => {
                  api.command.editPost.call(this, {
                    url: `/market/farmCountryImpress/${records.upDown == 0 ? 'down' : 'up'}?id=${records.id}`
                  })
                }
              },
              {
                name: '编辑',
                onClick: () => this.$router.push('/marketingCenter/ruralDetail?id=' + records.id)
              },
              {
                name: '删除',
                type: 'pop',
                popTitle: '确认是否删除吗?',
                onClick: () =>
                  api.command.delPost.call(this, {
                    url: `/market/farmCountryImpress/delete?id=${records.id}`
                  })
              }
            ]
          }
        }
      ]
    },
    getButton() {
      return [
        {
          name: '添加',
          type: 'primary',
          icon: 'plus',
          onClick: () => this.$router.push('/marketingCenter/ruralDetail')
        },
        {
          viewType: 'menu',
          name: '更多操作',
          isBatch: true,
          children: [
            {
              name: '批量删除',
              onClick: data => {
                let idList = data.map(e => e.id)
                return api.command.delPostArr.call(this, {
                  url: '/market/farmCountryImpress/batchDel',
                  params: { idList }
                })
              }
            },
            {
              name: '批量上架',
              onClick: data => {
                let idList = data.map(e => e.id)
                return api.command.editPost.call(this, {
                  url: '/market/farmCountryImpress/batchUp',
                  params: { idList }
                })
              }
            },
            {
              name: '批量下架',
              onClick: data => {
                let idList = data.map(e => e.id)
                return api.command.editPost.call(this, {
                  url: '/market/farmCountryImpress/batchDown',
                  params: { idList }
                })
              }
            }
          ]
        }
      ]
    }
  },
  render() {
    return (
      <TemplateHeaderTable
        headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        button={this.getButton()}
        records={this.records}
      />
    )
  }
}
</script>
